.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;

  i {
    opacity: 0.3;
  }
}

.error {
  color: red;
  font-size: 12px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.break-words {
  word-break: break-all;
}

.css-1s2u09g-control {
  background: transparent !important;
  color: #fff !important;
  border-radius: 30px !important;
  cursor: pointer;
}

.css-1pahdxg-control {
  background: transparent !important;
  border-radius: 30px !important;
}
.css-aoxytf-singleValue {
  grid-area: 1/1/2/3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #040404 !important;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
}