.toast_area {
    position: fixed;
    z-index: 9999;
    margin: 0 0 30px;
    display: flex;
    flex-direction: column;
}
.toast_box {
    width: 310px;
    height: 65px;    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 15px;
    border-radius: 4px;
    position: relative;
    
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    & + .toast_box {
        margin-top: 15px;
    }
    span {
        width: 100%;
        display: block;
    }
    .progress {
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 4px 4px 0 0;
        background: rgba(255,255,255,0.4);
        overflow: hidden;
        &::before {
            content: '';
            background: #ffffff;
            width: 0;
            height: 100%;
            display: block;
            top: 0;
            left: 0;
            animation: loading 4s linear;
        }
    }
    .close {
        width: 5px;
        height: 5px;
        display: block;
        position: absolute;
        right: 14px;
        top: 12px;
        padding: 0;
        border: none;
        background: none;
        font-family: 'Neutraface 2', 'sans-serif';
        font-weight: 400;
        font-size: 17px;
        line-height: 17px;
        color: #ffffff;
    }
}
.primary {
    background: #4175DF;
    span {
        color: #ffffff;
    }
}
.success {
    background: #0F9D58;
    span {
        color: #ffffff;
    }
}
.danger, .error {
    background: #EB4132;
    span {
        color: #ffffff;
    }
}
.warning {
    background: #FBBD00;
    span {
        color: #ffffff;
    }
}
.white {
    background: #ffffff;
    .progress {
        background: rgba(0,0,0,0.2);
        &::before {
            background: linear-gradient(90deg,#ffd33d,#ea4aaa 17%,#b34bff 34%,#01feff 51%,#ffd33d 68%,#ea4aaa 85%,#b34bff)
        }
    }
    span, .close {
        color: #101F4F;
    }
}
.bottom_center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: transform .6s ease-in-out;
	animation: fade .7s;
}
.bottom_right {
    bottom: 0;
    right: 30px;
    transition: transform .6s ease-in-out;
	animation: fade .7s;
}
.bottom_left {
    bottom: 0;
    left: 305px;
    transition: transform .6s ease-in-out;
	animation: fade .7s;
}
.top_center {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}
.top_right {
    top: 30px;
    right: 30px;
    transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}
.top_left {
    top: 30px;
    left: 305px;
    transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.pause:hover {
    .progress::before {
        animation-play-state: paused;
    }
}




@-webkit-keyframes loading {
    0%{
        width: 0%;
      }
      100%{
          width: 100%;
      }
}
@-moz-keyframes loading {
    0%{
        width: 0%;
      }
      100%{
          width: 100%;
      }
}
@keyframes loading {
    0%{
        width: 0%;
      }
      100%{
          width: 100%;
      }
}
@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
}
@keyframes fade {
	from {
		opacity: 0;		
	}
	to {
		opacity: 1;
	}
}
