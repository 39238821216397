// .loader_body {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   max-width: 100%;
// }

// .loader {
//   animation: rotate 10s infinite;
//   height: 50px;
//   width: 50px;


// }

// .loader:before,
// .loader:after {
//   border-radius: 50%;
//   content: '';
//   display: block;
//   height: 20px;
//   width: 20px;
// }

// .loader:before {
//   animation: ball1 1s infinite;
//   background-color: #808080;
//   box-shadow: 30px 0 0 #f8b334;
//   margin-bottom: 10px;
// }

// .loader:after {
//   animation: ball2 1s infinite;
//   background-color: #8F281F;
//   box-shadow: 30px 0 0 #808080;
// }

// .please_wait {
//   color: #ffffff;
//   margin-top: 2rem;
// }


// @keyframes rotate {
//   0% {
//     -webkit-transform: rotate(0deg) scale(0.8);
//     -moz-transform: rotate(0deg) scale(0.8);
//   }

//   50% {
//     -webkit-transform: rotate(360deg) scale(1.2);
//     -moz-transform: rotate(360deg) scale(1.2);
//   }

//   100% {
//     -webkit-transform: rotate(720deg) scale(0.8);
//     -moz-transform: rotate(720deg) scale(0.8);
//   }
// }

// @keyframes ball1 {
//   0% {
//     box-shadow: 30px 0 0 #808080;
//   }

//   50% {
//     box-shadow: 0 0 0 #808080;
//     margin-bottom: 0;
//     -webkit-transform: translate(15px, 15px);
//     -moz-transform: translate(15px, 15px);
//   }

//   100% {
//     box-shadow: 30px 0 0 #808080;
//     margin-bottom: 10px;
//   }
// }

// @keyframes ball2 {
//   0% {
//     box-shadow: 30px 0 0 #8F281F;
//   }

//   50% {
//     box-shadow: 0 0 0 #8F281F;
//     margin-top: -20px;
//     -webkit-transform: translate(15px, 15px);
//     -moz-transform: translate(15px, 15px);
//   }

//   100% {
//     box-shadow: 30px 0 0 #8F281F;
//     margin-top: 0;
//   }
// }

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden !important;
  background: url("../../images/background.jpg") no-repeat center top;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  transform: translateY(-0px);

  .loading_text {
    color: "#fff";
    font-size: 18px;
    padding-right: 30px;
    position: absolute;
    transform: translateY(150px);
  }

}




@media screen and (max-width: 920px) {
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden !important;
    background: url("../../images/background.jpg") no-repeat center top;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    transform: translateY(-50px) !important;

    .loading_text {
      color: "#fff";
      font-size: 18px;
      padding-left: 60px !important;
      position: absolute;
      transform: translateY(120px);
    }
  }

}