.not-found {
  margin: 0;
  font-family: 'Neutraface 2', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
  background: url("../../../src/images/background.jpg") no-repeat center top;
  background-size: cover;
  background-attachment: fixed;
  
  width: 100%;
}
.not-found > div {
  width: 100%;
    max-width: 72%;
  margin: auto;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 193px;
}
.notfound {
  width: calc(100% - 350px);
  position: relative;
}
.text-404 {
  color: rgb(3, 1, 5);
  font-size: 230px;
  font-style: normal;
  text-align: center;
  font-weight: 900;
  letter-spacing: 15px;
  background: linear-gradient(
    90deg,
    rgba(191, 59, 69, 1) 37%,
    rgba(191, 59, 69, 1) 47%
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  margin: 0;
}
.page-not-found-pos {
  position: relative;
}

.page-not-found {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 45px;
  letter-spacing: 10px;
  word-spacing: 10px;
  position: absolute;
  top: 65%;
  left: 0;
}
.book-img {
  display: none;
  width: 350px;
  position: absolute;
  top: 32%;
  right: 116px;
}
.book-img > img {
  width: 350px;
}
.btn {
  text-align: center;
}
.btn button {
  background: rgb(3, 1, 5);
  color: rgb(205, 62, 50);
  padding: 10px 30px;
  border: 2px solid rgb(205, 62, 50);
  font-size: 15px;
  font-weight: 600;
  box-shadow: 0px 3px 16px 4px rgb(205 62 50 / 89%);
  cursor: pointer;
  margin-top: 80px;
  
}
.btn button:hover {
}
.logoimg {
  width: 350px;
}
@media (max-width: 640px) {
  .book-img{
    display: none;
  }
  .page-not-found {
    width: 316px;
    /* text-align: center; */
    color: white;
    font-size: 25px;
    letter-spacing: 10px;
    word-spacing: 10px;
    position: absolute;
    top: 86%;
    left: 0;
}
.text-404{
  font-size: 152px;
  -webkit-text-stroke: 1px rgba(191, 59, 69, 1);
  -webkit-text-fill-color: rgb(0, 0, 0);
}
.btn button {
  
  position: absolute;
  left: 69px;
}
.not-found > div {
  padding: 0;
  display: block;
}

}