@font-face {
  font-family: "Neutraface 2";
  src: url("fonts/Neutraface2Text-Book.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neutraface 2";
  src: url("fonts/Neutraface2Text-Demi.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neutraface 2";
  src: url("fonts/Neutraface2Text-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a,
a:focus {
  text-decoration: none !important;
}

body {
  margin: 0;
  font-family: "Neutraface 2", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
  background: url("../src/images/background.jpg") no-repeat center top;
  background-size: cover;
  background-attachment: fixed;
}

.fixed {
  overflow: hidden;
}

button,
input,
select,
textarea {
  font-family: "Neutraface 2", "sans-serif";
}

button,
a {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.splash {
  position: relative;
  z-index: 8;
  overflow: hidden;
  border-radius: 30px;
}

.splash::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(50px);
  z-index: -1;
}

.splash::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: radial-gradient(
      64.65% 170.73% at 50% 50%,
      rgba(143, 25, 27, 0) 37.25%,
      rgba(143, 25, 27, 0.7) 100%
    ),
    radial-gradient(
      78.56% 197.03% at -24.62% -28.56%,
      rgba(143, 25, 27, 0.6) 0%,
      rgba(143, 25, 27, 0) 49.73%
    );
  opacity: 0.35;
  backdrop-filter: blur(50px);
  z-index: -1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  width: 5px;
  box-shadow: none !important;
  background: #2e2e2e;
}

body::-webkit-scrollbar-thumb {
  width: 5px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

ul {
  padding: 0;
}

.active {
  background: #0f9d58;
}

.expired {
  background: #eb4132;
}

.swiper-button-disabled {
  pointer-events: none;
  display: none !important;
}

.message_field {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  margin: 0 0 30px;
  position: relative;
}

.message_field label {
  width: 80px;
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #9a9a9a;
  padding: 0 0 0 20px;
  text-transform: capitalize;
}

.message_field > div {
  width: calc(100% - 80px);
  margin: 0;
  padding: 0;
}

.message_field > div button,
.message_field > div input {
  border: none;
}

.message_editor {
  width: 100%;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  margin: 0 0 30px;
  position: relative;
}

.message_editor label {
  display: none;
}

.message_editor > div {
  padding: 0;
}

.message_editor .demo-wrapper {
  border: none !important;
}

.message_editor .rdw-editor-toolbar {
  position: absolute;
  width: 100%;
  bottom: 0;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 100;
}

.message_editor .demo-editor {
  height: 410px;
}

.rdw-option-disabled {
  display: none !important;
}

.rdw-link-decorator-wrapper a {
  color: #ffffff;
  text-decoration: underline;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 5px;
}

.infinite-scroll-component::-webkit-scrollbar-track {
  width: 5px;
  box-shadow: none !important;
  background: #2e2e2e;
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  width: 5px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

iframe {
  display: none;
}
